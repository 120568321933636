import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Img from 'gatsby-image'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { ArticleSectionParagraph } from '../../../../interfaces/articles'
import { RootState } from '../../../../redux/store'
import { getInternationalizedValue } from '../../../../utils/common'
import TextMarkdown from '../../../customMarkdown/textMarkdown'

const useStyles = makeStyles((theme: Theme) => ({
  container: (props: { isOnlyTitle: boolean }) => ({
    // [theme.breakpoints.up('md')]: {
    //   marginLeft: theme.spacing(2),
    //   marginRight: theme.spacing(2),
    // },
    // [theme.breakpoints.down('sm')]: {
    // },
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    backgroundColor: props.isOnlyTitle ? theme.palette.primary.main : 'white',
    color: props.isOnlyTitle ? 'white' : 'black',
  }),
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

interface ParagraphSectionProps {
  paragraphSection: ArticleSectionParagraph
}

const ParagraphSection: FC<ParagraphSectionProps> = props => {
  const { paragraphSection } = props

  const isOnlyTitle = !paragraphSection.paragraph.length
  const { container, imageContainer } = useStyles({ isOnlyTitle })

  const { language } = useSelector((state: RootState) => state.app)
  const title = getInternationalizedValue(
    paragraphSection,
    'section_title',
    language,
  )

  return (
    <Paper elevation={8} className={container} id={title}>
      <Box px={3} pt={2}>
        <Typography variant="h6">{title}</Typography>
      </Box>
      {paragraphSection.paragraph.map(p => {
        const text = getInternationalizedValue(p, 'section_paragraph', language)
        const image = p.image ? p.image.childImageSharp.fixed : null
        // console.log('!!', image)

        return (
          <Box key={`article-paragraph-${p.id}`}>
            <Box paddingX={3}>
              {/* <Typography variant="body1"> */}
              <TextMarkdown text={text} />
              {/* </Typography> */}
            </Box>
            <Box className={imageContainer} marginY={1}>
              {image ? <Img fixed={image} alt="paragraph image" /> : null}
            </Box>
          </Box>
        )
      })}
    </Paper>
  )
}

export default ParagraphSection
