import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { ArticlesTypesEnum } from '../../../../enum/articles'
import { ArticleSectionParagraph } from '../../../../interfaces/articles'
import { RootState } from '../../../../redux/store'
import { getInternationalizedValue } from '../../../../utils/common'
import CustomStep from '../../../customButtons/customStep'
import SimpleAnchor from '../../../customLinks/simpleAnchor'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    // [theme.breakpoints.up('md')]: {
    //   marginLeft: theme.spacing(2),
    //   marginRight: theme.spacing(2),
    // },
    // [theme.breakpoints.down('sm')]: {
    // },
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1.5),
  },
  titleClass: {
    fontWeight: 'bold',
    color: '#828282',
  },
}))

interface ArticleNavigationProps {
  type: string
  paragraph: ArticleSectionParagraph[]
}

const ArticleNavigation: FC<ArticleNavigationProps> = props => {
  const { type, paragraph } = props

  const classes = useStyles()

  const { language } = useSelector((state: RootState) => state.app)

  const title =
    type === ArticlesTypesEnum.ARTICLE
      ? 'Dans cet article'
      : ArticlesTypesEnum.FICHE_ENTREPRISE
      ? 'Les étapes'
      : ''

  const createNavigation = (
    key: string,
    sectionTitle: string,
    type: string,
    index: number,
  ): React.ReactNode => {
    switch (type) {
      case ArticlesTypesEnum.ARTICLE:
        return (
          <Box marginY={1} key={key}>
            <SimpleAnchor
              item={{ label: sectionTitle, path: sectionTitle }}
              offset={-64}
            />
          </Box>
        )

      case ArticlesTypesEnum.FICHE_ENTREPRISE:
        return (
          <Box key={key}>
            <CustomStep
              index={index + 1}
              item={{ label: sectionTitle, path: sectionTitle }}
              offset={-64}
              isLast={index + 1 === paragraph.length}
            />
          </Box>
        )

      default:
        return null
    }
  }

  return (
    <Paper elevation={8} className={classes.container}>
      <Box marginBottom={3}>
        <Typography variant="body1" className={classes.titleClass}>
          {title}
        </Typography>
      </Box>
      <Box>
        {paragraph.map((p, index) => {
          const currentSectionTitle = getInternationalizedValue(
            p,
            'section_title',
            language,
          )

          return createNavigation(
            `article-nav-${p.id}`,
            currentSectionTitle,
            type,
            index,
          )
        })}
      </Box>
    </Paper>
  )
}

export default ArticleNavigation
