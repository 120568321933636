import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React, { FC } from 'react'
import TextMarkdown from '../../../customMarkdown/textMarkdown'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    // [theme.breakpoints.up('md')]: {
    //   marginLeft: theme.spacing(2),
    //   marginRight: theme.spacing(2),
    // },
    // [theme.breakpoints.down('sm')]: {
    // },
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1.5),
  },
  titleContainer: {},
  dateContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    textTransform: 'capitalize',
  },
  descriptionContainer: {},
}))

interface BigTitleSectionProps {
  bigTitle: string
  createdAt: string
  updatedAt: string
  description: string
}

const BigTitleSection: FC<BigTitleSectionProps> = props => {
  const { bigTitle, createdAt, updatedAt, description } = props

  const {
    container,
    titleContainer,
    dateContainer,
    descriptionContainer,
  } = useStyles()

  const fullDate = `Créé: ${createdAt} - Mise à jour : ${updatedAt}`

  return (
    <Paper elevation={8} className={container}>
      <Box className={titleContainer}>
        <Typography variant="h4">{bigTitle}</Typography>
      </Box>
      <Box className={dateContainer}>
        <Typography variant="body2">{fullDate}</Typography>
      </Box>
      <Box className={descriptionContainer}>
        <Typography variant="body1">
          <TextMarkdown text={description} />
        </Typography>
      </Box>
    </Paper>
  )
}

export default BigTitleSection
