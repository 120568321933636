import { Box, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { graphql } from 'gatsby'
import React, { FC, useEffect, useRef, useState } from 'react'
import Swiper from 'react-id-swiper'
import { useSelector } from 'react-redux'
import ArticleBanner from '../components/advertising/articleBanner'
import SwiperNavigation from '../components/libs/swiperNavigation'
import ArticleNavigation from '../components/pages/articles/articleNavigation'
import ArticleSimilar from '../components/pages/articles/articleSimilar'
import BigTitleSection from '../components/pages/articles/bigTitleSection'
import ParagraphSection from '../components/pages/articles/paragraphSection'
import SEO from '../components/seo'
import { SWIPER_PARAMS } from '../constants/common'
import { ArticleData } from '../interfaces/articles'
import { RootState } from '../redux/store'
import {
  getArticlePath,
  getInternationalizedDate,
  getInternationalizedValue,
} from '../utils/common'

export const query = graphql`
  query($articleId: String) {
    allStrapiArticles(filter: { id: { eq: $articleId } }) {
      nodes {
        id
        big_title_fr
        created_at(formatString: "")
        updated_at(formatString: "")
        description_fr
        type
        section_paragraph {
          id
          section_title_fr
          paragraph {
            id
            section_paragraph_fr
            image {
              childImageSharp {
                id
                fixed(width: 375) {
                  base64
                  width
                  height
                  src
                  srcSet
                }
              }
            }
          }
        }
        articles_similaire {
          id
          big_title_mg
          big_title_fr
          big_title_en
          description_mg
          description_fr
          description_en
        }
      }
    }
  }
`

interface StyleProps {
  displayNavigation: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // marginTop: theme.spacing(12),
    width: '98%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    borderRadius: theme.spacing(3),

    background:
      'linear-gradient(180deg, #C9FFEF 0%, rgba(255, 255, 255, 0) 100%), #FFF',
  },
  container: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    padding: theme.spacing(2),
  },
  content: (props: StyleProps) => ({
    width: props.displayNavigation ? '75%' : '100%',
    order: -1,
    [theme.breakpoints.down('sm')]: {
      width: '96%',
      order: 1,
    },
    margin: theme.spacing(1),
  }),
  navigation: (props: StyleProps) => ({
    display: props.displayNavigation ? 'block' : 'none',
    // width: '25%',
    width: '96%',
    [theme.breakpoints.down('sm')]: {
      width: '96%',
    },
    margin: theme.spacing(1),
  }),
  articleTitle: {
    fontSize: 32,
    fontWeight: 'bold',
  },
}))

interface ArticlesProps {
  data: any
}

const Articles: FC<ArticlesProps> = props => {
  const { data } = props

  const swiperRef = useRef<any>(null)

  const [isMobileView, setIsMobileView] = useState(false)

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth <= 768
        ? setIsMobileView(true)
        : setIsMobileView(false)
    }

    setResponsiveness()

    window.addEventListener('resize', () => setResponsiveness())
  }, [])

  const articleData: ArticleData = data.allStrapiArticles.nodes[0]
  // console.log('!!!!', articleData)
  const { language } = useSelector((state: RootState) => state.app)

  const { created_at, updated_at, section_paragraph } = articleData
  const bigTitle = getInternationalizedValue(articleData, 'big_title', language)
  const description = getInternationalizedValue(
    articleData,
    'description',
    language,
  )

  const articleSimilars = articleData.articles_similaire

  const createdAt = getInternationalizedDate(created_at, language)
  const updatedAt = getInternationalizedDate(updated_at, language)

  const { root, container, content, navigation, articleTitle } = useStyles({
    displayNavigation: !!section_paragraph.length,
  })

  return (
    <Box className={root}>
      <SEO title="Articles" />
      <Box className={container}>
        {/* Invert menu links position according to screen size */}
        {/* And adjust navigation position according to screen size (client feedback) */}
        {isMobileView ? null : (
          <Box>
            <Box className={navigation}>
              <ArticleNavigation
                type={articleData.type}
                paragraph={section_paragraph}
              />
            </Box>
            <ArticleBanner />
          </Box>
        )}

        {/* Content container */}
        <Box className={content}>
          <BigTitleSection
            bigTitle={bigTitle}
            createdAt={createdAt}
            updatedAt={updatedAt}
            description={description}
          />

          {isMobileView ? (
            <Box>
              <ArticleBanner />
              <Box className={navigation}>
                <ArticleNavigation
                  type={articleData.type}
                  paragraph={section_paragraph}
                />
              </Box>
            </Box>
          ) : null}

          {section_paragraph.map(s => (
            <ParagraphSection
              key={`article-section-${s.id}`}
              paragraphSection={s}
            />
          ))}

          {/* Similar articles sections */}
          {articleSimilars.length ? (
            <Box>
              <Box paddingY={3}>
                <Typography className={articleTitle}>
                  Articles similaires
                </Typography>
              </Box>
              <Box display="flex" paddingLeft={1}>
                <Swiper {...SWIPER_PARAMS} ref={swiperRef}>
                  {articleSimilars.map(a => {
                    const aTitle = getInternationalizedValue(
                      a,
                      'big_title',
                      language,
                    )
                    const aDesc = getInternationalizedValue(
                      a,
                      'description',
                      language,
                    )
                    const path = getArticlePath(a.id)
                    return (
                      <Box key={`article-similar-${a.id}`}>
                        <ArticleSimilar
                          titleLink={{ label: aTitle, path }}
                          description={aDesc}
                        />
                      </Box>
                    )
                  })}
                </Swiper>
              </Box>
              <Box marginY={2}>
                <SwiperNavigation swiperRef={swiperRef} />
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}

export default Articles
