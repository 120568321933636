import { Box, Divider, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { FC } from 'react'
import { Link } from 'react-scroll'
import { MenuLinkItem } from '../../../interfaces/common'

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  indexContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(1),
  },
  index: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    borderRadius: 12,
    color: theme.palette.primary.main,
    border: `solid ${theme.palette.primary.main} 1px`,
    // '&:hover': {
    //   color: theme.palette.primary.dark,
    //   border: `solid ${theme.palette.primary.dark} 1px`,
    //   cursor: 'pointer',
    // },
  },
  item: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
      cursor: 'pointer',
    },
  },
  divider: {
    marginLeft: theme.spacing(1.75),
    height: theme.spacing(2),
  },
}))

interface CustomStepProps {
  index: number
  item: MenuLinkItem
  isLast?: boolean
  offset?: number
  duration?: number
  handleClick?: (anchor?: string) => () => void
}

const CustomStep: FC<CustomStepProps> = props => {
  const { index, item, isLast, handleClick } = props

  const duration = props.duration || 500
  const offset = props.offset || 0

  const classes = useStyles()

  return (
    <Box className={classes.root} onClick={handleClick && handleClick()}>
      <Box display="flex" alignItems="center">
        <Box className={classes.indexContainer}>
          <Box className={classes.index}>
            <Typography variant="caption">{index}</Typography>
          </Box>
        </Box>
        <Box>
          {/* <Typography variant="body1">    </Typography> */}
          <Link
            className={classes.item}
            to={item.path}
            spy={true}
            smooth={true}
            duration={duration}
            offset={offset}
          >
            {item.label}
          </Link>
        </Box>
      </Box>
      {isLast ? null : (
        <Box className={classes.divider}>
          <Divider orientation="vertical" />
        </Box>
      )}
    </Box>
  )
}

export default CustomStep
