import Box from '@material-ui/core/Box'
import React from 'react'
import useAllArticleAdvertising from '../../../hooks/advertisingBanners/useAllArticleAdvertising'
import { getAdvertisingImageItems } from '../../../utils/advertisingBanner'
import PubCarousel from '../../common/pubCarousel'

const ArticleBanner = () => {
  const data = useAllArticleAdvertising()
  const { desktop, mobile } = getAdvertisingImageItems(data)

  return (
    <>
      {desktop.length || mobile.length ? (
        <Box mt={4} mb={4}>
          <PubCarousel
            width={300}
            height={250}
            imagesData={desktop.length ? desktop : mobile}
          />
        </Box>
      ) : null}
    </>
  )
}

export default ArticleBanner
