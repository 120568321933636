import { Theme, Typography, TypographyProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { FC } from 'react'
import { MenuLinkItem } from '../../../interfaces/common'
import { Link } from 'react-scroll'

const useStyles = makeStyles((theme: Theme) => ({
  link: (props: { isBold?: boolean }) => ({
    fontWeight: props.isBold ? 'bold' : 'normal',
    textDecoration: 'none',
    // color: props.isContent ? theme.palette.primary.main : '#4F4F4F',
    color: theme.palette.primary.main,
    '&:hover': {
      // color: props.isContent
      //   ? theme.palette.primary.dark
      //   : theme.palette.primary.main,
      color: theme.palette.primary.dark,
      cursor: 'pointer',
    },
  }),
}))

export interface SimpleAnchorProps {
  item: MenuLinkItem
  isBold?: boolean
  offset?: number
  duration?: number
  handleClick?(): void
}

const SimpleAnchor: FC<SimpleAnchorProps & TypographyProps> = props => {
  const { item, isBold, handleClick } = props
  const classes = useStyles({ isBold })

  const duration = props.duration || 500
  const offset = props.offset || 0

  return (
    <Typography variant={props.variant || 'subtitle1'}>
      <Link
        className={classes.link}
        to={item.path}
        spy={true}
        smooth={true}
        duration={duration}
        offset={offset}
      >
        {item.label}
      </Link>
    </Typography>
  )
}

export default SimpleAnchor
