import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { FC } from 'react'
import { MenuLinkItem } from '../../../../interfaces/common'
import SimpleLink from '../../../customLinks/simpleLink'
import TextMarkdown from '../../../customMarkdown/textMarkdown'
import PerfectScrollbar from 'react-perfect-scrollbar'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(3),
    minHeight: 240,
    [theme.breakpoints.down('sm')]: {
      minHeight: 260,
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 300,
    },
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      1.5,
    )}px ${theme.spacing(1)}px`,
    borderRadius: theme.spacing(1.5),
  },

  titleContainer: {
    // height: '22%',
    height: 60,
  },
  descriptionStyles: {
    // height: '77%',
    overflowY: 'auto',
    height: 220,
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
  },
  // ellipsis: {
  //   overflow: 'hidden',
  //   textOverflow: 'ellipsis',
  //   whiteSpace: 'nowrap',
  //   height: 220,
  // },
}))

interface ArticleSimilarProps {
  titleLink: MenuLinkItem
  description: string
}

const ArticleSimilar: FC<ArticleSimilarProps> = props => {
  const {
    titleLink: { label, path },
    description,
  } = props

  const { container, titleContainer, descriptionStyles } = useStyles()

  return (
    <Paper elevation={8} className={container}>
      <Box className={titleContainer}>
        <SimpleLink isContent={true} item={{ label: label, path }} />
      </Box>
      <Box className={descriptionStyles}>
        <PerfectScrollbar>
          <Typography variant="body1">
            <TextMarkdown text={description} />
            {/* {description} */}
          </Typography>
        </PerfectScrollbar>
      </Box>
    </Paper>
  )
}

export default ArticleSimilar
